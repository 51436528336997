const routesConfig = [
  {
    id: 'user',
    title: 'User Management',
    messageId: 'sidebar.connection',
    path: '/user',
    type: 'item',
    icon: 'icon-ic_profile',
    permission: 'USER:R',
  },
  {
    id: 'mentor-request',
    title: 'Mentor Request',
    messageId: 'sidebar.connection',
    path: '/mentor-request',
    type: 'item',
    icon: 'icon-ic_anonymous',
    permission: 'APPROVAL:R',
  },
  {
    id: 'banner',
    title: 'Media Management',
    messageId: 'sidebar.image',
    path: '/banner',
    type: 'item',
    icon: 'icon-ic_image',
    permission: 'CONTENT:R',
  },
  {
    id: 'voice',
    title: 'Voice Management',
    messageId: 'sidebar.voice',
    path: '/voice',
    type: 'item',
    icon: 'icon-ic_message',
    permission: 'VOICE:R',
  },
  {
    id: 'group',
    title: 'Community Management',
    messageId: 'sidebar.groups',
    path: '/group',
    type: 'item',
    icon: 'icon-ic_network',
    permission: 'COMMUNITY:R',
  },
  {
    id: 'schedules',
    title: 'Mentoring Session Management',
    messageId: 'sidebar.groups',
    path: '/schedules',
    type: 'item',
    icon: 'icon-ic_calendar',
    permission: 'SESSION:R',
  },
  {
    id: 'event',
    title: 'MSG Event Management',
    messageId: 'sidebar.event',
    path: '/event',
    type: 'item',
    icon: 'msg-icon ico2-ic_event',
    permission: 'EVENT:R',
  },
  {
    id: 'report',
    title: 'Report',
    messageId: 'sidebar.report',
    path: '/report',
    type: 'item',
    icon: 'icon-sliders',
    permission: 'REPORT:R',
  },
  {
    id: 'role',
    title: 'Role Management',
    messageId: 'sidebar.role',
    path: '/role',
    type: 'item',
    icon: 'icon-ic_hexagon',
    permission: 'PERMISSION:R',
  },
];
export default routesConfig;
